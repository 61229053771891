import { IncidentLocations } from '@/pages/api/map-events/incident-locations.types'
import {
  AssetItem,
  IncidentItem,
  IncidentTypeItem,
  SeverityItem,
} from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { ROUTE, instance } from './instance'

export const mapEvents = {
  getAssets: async () => {
    const { data } = await instance.get<AssetItem[]>(ROUTE.MAP_EVENTS.ASSETS)
    return data
  },

  getIncidents: async () => {
    const { data } = await instance.get<IncidentItem[]>(ROUTE.MAP_EVENTS.INCIDENTS)
    return data
  },

  getIncidentsByQuery: async ({ question }: { question: string }) => {
    const { data } = await instance.post<IncidentItem[]>(ROUTE.MAP_EVENTS.INCIDENTS_QUERY, { question })
    return data
  },

  getIncidentsCount: async () => {
    const { data } = await instance.get<{ count: number }>(ROUTE.MAP_EVENTS.INCIDENTS_COUNT)
    return data
  },

  getIncidentLocations: async () => {
    const { data } = await instance.get<IncidentLocations>(ROUTE.MAP_EVENTS.INCIDENT_LOCATIONS)
    return data
  },

  getIncidentTypes: async () => {
    const { data } = await instance.get<IncidentTypeItem[]>(ROUTE.MAP_EVENTS.INCIDENT_TYPES)
    return data
  },

  getSeverityLevels: async () => {
    const { data } = await instance.get<SeverityItem[]>(ROUTE.MAP_EVENTS.SEVERITY_LEVELS)
    return data
  },

  getTargetPhoto: async (data: { assetId?: AssetItem['AssetID'] }) => {
    const url = instance.getUri({ url: ROUTE.MAP_EVENTS.TARGET_PHOTO, params: { ...data } })
    return fetch(url, { method: 'GET' })
      .then((r) => {
        const contentType = r.headers.get('Content-Type')
        if (contentType === 'application/octet-stream') {
          return r.blob()
        }
        return null
      })
      .then((blob) => {
        const objectURL = blob ? URL.createObjectURL(blob) : undefined
        return { imageUrl: objectURL }
      })
  },
}
