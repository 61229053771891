import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { Api } from '@/core/api'
import { AssetItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { useLinkPreviewImageData } from '../../../hooks/useLinkPreviewImageData'
import { TargetCard } from '../TargetCard'

interface TargetAssetCardProps {
  target: AssetItem
}

export const TargetAssetCard: React.FC<TargetAssetCardProps> = ({ target }) => {
  // const { data, isLoading } = useQuery({
  //   queryKey: ['asset-image', target.AssetID],
  //   queryFn: () => Api.mapEvents.getTargetPhoto({ assetId: target.AssetID }),
  // })

  const { data, isLoading } = useLinkPreviewImageData({ link: target.Hyperlink })

  return (
    <div>
      <TargetCard
        details={{
          addressName: target.Address,
          coords: { lat: target.Latitude, lng: target.Longitude },
          contact: { email: target.FocalPointEmail, name: target.FocalPointName, phone: target.FocalPointPhone },
        }}
        imageSrc={data?.image}
        isImageLoading={isLoading}
        link={target.Hyperlink}
        description={target.AssetDescription}
      />
    </div>
  )
}
