import React from 'react'
import { Textarea as CTextarea, TextareaProps as CTextareaProps } from '@chakra-ui/react'
import classnames from 'classnames'
import styles from './Input.module.scss'

export interface TextareaProps extends CTextareaProps {}

export const Textarea: React.FC<TextareaProps> = ({ className, ...props }) => (
  <CTextarea className={classnames(styles.container, styles.textarea, className)} {...props} />
)
