type KeyLocalState<T = Record<string, unknown> | Array<unknown>> = Record<string, T>

const serialize = <T>(storageKey: string): KeyLocalState<T> => {
  const data = localStorage.getItem(storageKey)
  try {
    return data ? JSON.parse(data) : {}
  } catch (e) {
    console.error(e)
    localStorage.removeItem(storageKey)
  }
  return {}
}

export class Storage {
  storageKey: string

  constructor(storageKey: string) {
    this.storageKey = `app.${storageKey}`
  }

  setValue = <T = Record<string, unknown> | Array<unknown>>(
    key: string,
    value: T,
    onError?: (e: unknown) => void,
  ): void => {
    const data = {
      ...serialize(this.storageKey),
      [key]: value,
    }
    try {
      localStorage.setItem(this.storageKey, JSON.stringify(data))
    } catch (e) {
      onError && onError(e)
    }
  }

  getValue = <T = Record<string, unknown> | Array<unknown>>(key: string): T => serialize<T>(this.storageKey)[key]

  removeValue = (key: string) => {
    const data = serialize(this.storageKey)
    delete data[key]
    try {
      localStorage.setItem(this.storageKey, JSON.stringify(data))
    } catch (e) {
      //
    }
  }

  clear = (): void => localStorage.removeItem(this.storageKey)

  toJSON = () => serialize(this.storageKey)
}
