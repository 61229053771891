import React from 'react'
import classnames from 'classnames'
import { AsidePanel, type AsidePanelProps } from '@/components/common/AsidePanel'
import { CloseButton } from '@/components/common/Button'
import styles from './BasePanel.module.scss'

export interface BasePanelProps extends Pick<AsidePanelProps, 'className'> {
  title: string
  controls?: React.ReactNode
  onClickClose?(): void
}

export const BasePanel: React.FC<React.PropsWithChildren<BasePanelProps>> = ({
  className,
  title,
  controls,
  onClickClose,
  children,
}) => (
  <AsidePanel className={classnames(styles.container, className)} position="left">
    <div className={styles.titleWrapper}>
      <div className="flex items-center">
        {onClickClose && <CloseButton className={styles.closeBtn} onClick={onClickClose} />}
        <div className={styles.title}>{title}</div>
      </div>
      {controls && <div className="flex">{controls}</div>}
    </div>
    <div className={styles.body}>{children}</div>
  </AsidePanel>
)
