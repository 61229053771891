import React from 'react'
import { Loader } from '@/components/common/Loader'
import { useMapEventsContext } from '../../context/MapEventsContextProvider'
import styles from './LoadingDataIndicator.module.scss'

export const LoadingDataIndicator = () => {
  const { isLoadingData } = useMapEventsContext()

  if (!isLoadingData) {
    return null
  }

  return (
    <div className={styles.container}>
      <Loader size="lg" />
    </div>
  )
}
