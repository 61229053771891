import { MapboxAddressAutofill, MapboxGeocoder, MapboxSearchBox } from '@mapbox/search-js-web'
import mapbox from 'mapbox-gl'
import { Colors } from '@/styles/colors'
import { MAPBOX_SEARCH_CONTAINER_ID } from '../constants'

const markerIcon = `
  <svg display="block" height="41px" width="27px" viewBox="0 0 27 41">
      <path fill="${Colors.Blackcurrant_50}"
          d="M27,13.5C27,19.07 20.25,27 14.75,34.5C14.02,35.5 12.98,35.5 12.25,34.5C6.75,27 0,19.22 0,13.5C0,6.04 6.04,0 13.5,0C20.96,0 27,6.04 27,13.5Z">
      </path>
      <path opacity="0.25"
          d="M13.5,0C6.04,0 0,6.04 0,13.5C0,19.22 6.75,27 12.25,34.5C13,35.52 14.02,35.5 14.75,34.5C20.25,27 27,19.07 27,13.5C27,6.04 20.96,0 13.5,0ZM13.5,1C20.42,1 26,6.58 26,13.5C26,15.9 24.5,19.18 22.22,22.74C19.95,26.3 16.71,30.14 13.94,33.91C13.74,34.18 13.61,34.32 13.5,34.44C13.39,34.32 13.26,34.18 13.06,33.91C10.28,30.13 7.41,26.31 5.02,22.77C2.62,19.23 1,15.95 1,13.5C1,6.58 6.58,1 13.5,1Z">
      </path>
      <circle fill="white" cx="13.5" cy="13.5" r="5.5"></circle>
  </svg>
`

/**
 * README:
 * https://docs.mapbox.com/mapbox-search-js/api/web/search/#mapboxsearchbox#search
 */
export class MapSearch {
  private map: mapbox.Map
  searchBoxElement: MapboxSearchBox | undefined

  constructor({ map }: { map: mapbox.Map }) {
    this.map = map
  }

  init(config: { mapbox: MapboxSearchBox['mapboxgl']; accessToken: string; options: MapboxSearchBox['options'] }) {
    this.searchBoxElement = new MapboxSearchBox()
    this.searchBoxElement.accessToken = config.accessToken
    this.searchBoxElement.options = config.options
    this.searchBoxElement.mapboxgl = config.mapbox
    this.searchBoxElement.theme = {
      variables: {
        fontFamily: 'Montserrat,sans-serif',
        fontWeightBold: '600',
        fontWeightSemibold: '500',
        colorBackground: Colors.menuColor,
        colorBackgroundHover: Colors.borderColor,
        colorBackgroundActive: Colors.Neutral_90,
        colorText: Colors.White,
        colorPrimary: Colors.White,
        colorSecondary: Colors.Neutral,
        border: `1px solid ${Colors.borderColor}`,
        boxShadow: '1px 2px 12px 0px #00000080;',
      },
      icons: {
        marker: markerIcon,
        addressMarker: markerIcon,
        street: markerIcon,
      },
      cssText: `
        input[type="text"] { color: ${Colors.White}; }
        input[type="text"]:focus { color: ${Colors.White}; border: none; }
      `,
    }

    this.searchBoxElement.bindMap(this.map)
    this.appendIntoElement()

    return this
  }

  private appendIntoElement() {
    const elementID = document.getElementById(MAPBOX_SEARCH_CONTAINER_ID)
    if (elementID && elementID.hasChildNodes() && elementID.firstChild) {
      elementID.removeChild(elementID.firstChild)
    }
    elementID?.appendChild(this.searchBoxElement as Element)
  }
}
