import React from 'react'
import { Loader } from '@/components/common/Loader'
import { lazyComponent } from '@/components/common/utils/lazyImport'
import { BasePanel } from '../BasePanel'

export const LazyFiltersPanel = lazyComponent(() => import(/* webpackChunkName: 'FiltersPanel' */ './FiltersPanel'), {
  preload: true,
  loading: () => (
    <BasePanel title="Incident Filter" onClickClose={() => null}>
      <Loader />
    </BasePanel>
  ),
})
