import axios from 'axios'
import { Env } from '@/utils/env'
import { mapEvents } from './api.map-events'
// import { reports } from './api.reports'
import { AxiosError, ROUTE, instance, isAxiosError, isCancel } from './instance'

export type { AxiosError }

if (Env.isDev()) {
  Object.assign(window, { axios })
}

type AppVersion = {
  buildId: string
  timestamp: string
  GITHUB_SHA: string
  GITHUB_RUN_ID: string
  GITHUB_RUN_NUMBER: string
}

export const Api = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,

  isAxiosError,
  isCancel,

  mapEvents,

  getLinkPreviewImage: async (args: { link: string }) => {
    const { data } = await instance.get<{ image: string }>(ROUTE.LINK_PREVIEW_IMAGE, {
      params: { link: args.link },
    })
    return data
  },

  appVersion: async () => {
    const data = await axios
      .get<AppVersion>(`/version.txt?timestamp=${Date.now()}`)
      .then((resp) => resp.data)
      .catch((err) => ({}))

    const buildId = typeof window !== 'undefined' ? window.__NEXT_DATA__?.buildId : ''
    return { ...data, buildId }
  },

  // reports,
}
