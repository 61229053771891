import React from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useMapContext } from '../context/MapContextProvider'
import './MapView.scss'
import { MAPBOX_CONTAINER_ID, MAPBOX_SEARCH_CONTAINER_ID } from './constants'

export const MapBoxView = () => {
  const { mapRefEl } = useMapContext()

  return (
    <>
      <div id={MAPBOX_CONTAINER_ID} ref={mapRefEl} style={{ height: 'inherit' }} />
      <div id={MAPBOX_SEARCH_CONTAINER_ID} />
    </>
  )
}
