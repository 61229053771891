'use client'

import React from 'react'
import { MapBoxView } from './MapView'
import styles from './SituationalAwarenessPage.module.scss'
import { LeftSidebar } from './components/LeftSidebar'
import { LoadingDataIndicator } from './components/LoadingDataIndicator'
import { LazyMapDemoControls } from './components/MapDemoControls'
import { RightSidebarDrawer } from './components/RightSidebarDrawer'
import { CopilotFilterControl } from './components/panels/CopilotFilterControl'
import { ContextsProviders } from './context/ContextsProviders'

export const SituationalAwarenessPage = () => {
  return (
    <div className={styles.container}>
      <ContextsProviders>
        <MapBoxView /> {/* README: used via "mapRefEl" */}
        <LazyMapDemoControls className={styles.demoPanel} />
        <LeftSidebar />
        <RightSidebarDrawer />
        <CopilotFilterControl />
        <LoadingDataIndicator />
      </ContextsProviders>
    </div>
  )
}
