import { IncidentTypeItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { getIncidentTypeIdColor } from '../../components/utils/getIncidentTypeColor'
import { GeoJSONSourceOptions, MapManager } from '../MapManager'
import { LAYER, SOURCE, TextColor } from './MapDataDrawer.constants'
import { ImageName, type LatLng, applyStaticEventCoords, createPopupContentElement } from './MapDataDrawer.custom'
import { findCenter } from './MapDataDrawer.utils'

export interface RenderAssetsItem extends LatLng {
  id: number | string
  title: string
  description: string
}

export interface RenderIncidentsItem extends LatLng {
  id: number | string
  incidentTypeId: IncidentTypeItem['IncidentTypeID']
  title: string
  description: string
}

// TODO: Render incidents by grouped dots
// https://docs.mapbox.com/mapbox-gl-js/example/cluster/
// https://docs.mapbox.com/mapbox-gl-js/example/cluster-html/

export class MapDataDrawer {
  map: MapManager | undefined

  setMap = (map: MapManager) => {
    this.map = map
    return this
  }

  hasMap = () => !!this.map

  resetActiveTargets = () => {
    this.map?.setLayoutProperty(LAYER.ASSETS, 'icon-image', ImageName.ASSET_ICON)
  }

  renderAssets = (args: { items: RenderAssetsItem[]; onClick: (item: RenderAssetsItem) => void }) => {
    const source = this.map?.getSource(SOURCE.ASSETS)

    const data: GeoJSONSourceOptions['data'] = {
      type: 'FeatureCollection',
      features: args.items.map((item) => ({
        type: 'Feature',
        properties: item,
        geometry: {
          type: 'Point',
          coordinates: [item.longitude, item.latitude],
        },
      })),
    }

    if (source) {
      this.map?.updateSourceData(SOURCE.ASSETS, data)
    } else {
      this.map?.addSource(SOURCE.ASSETS, { type: 'geojson', data })
      this.map?.addLayer({
        id: LAYER.ASSETS,
        source: SOURCE.ASSETS,
        type: 'symbol',
        layout: {
          'icon-image': ImageName.ASSET_ICON,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.5, 15, 1],
          'icon-allow-overlap': true,
          'text-field': ['get', 'title'],
          'text-size': 14,
          'text-offset': [0, 2],
          'text-font': ['DIN Pro Regular', 'Arial Unicode MS Regular'],
        },
        paint: {
          'text-color': TextColor.Neutral,
        },
      })

      // Update icon for active selected asset
      const setActiveIcon = (currentPointId: RenderAssetsItem['id']) =>
        this.map
          ?.getMap()
          ?.setLayoutProperty(LAYER.ASSETS, 'icon-image', [
            'match',
            ['get', 'id'],
            currentPointId,
            ImageName.ASSET_ACTIVE_ICON,
            ImageName.ASSET_ICON,
          ])

      this.map
        ?.addLayerMouseEvents(LAYER.ASSETS)
        .on('click', (e) => {
          const properties = e.features![0].properties as RenderAssetsItem
          const currentPoint = properties.id
          args.onClick(properties)
          setActiveIcon(currentPoint)
        })
        .on('mouseover', (e) => {
          const properties = e.features![0].properties as RenderAssetsItem
          applyStaticEventCoords(e, properties) // Make always popup on target coords (not mouse event)
          this.map?.showPopup(e, {
            offset: [0, -12],
            layerId: LAYER.ASSETS,
            content: createPopupContentElement({ title: properties.title, description: properties.description }),
          })
        })
    }
  }

  renderIncidents = (args: {
    items: RenderIncidentsItem[]
    withCentrify?: boolean
    onClick: (item: RenderIncidentsItem) => void
  }) => {
    const source = this.map?.getSource(SOURCE.INCIDENTS)

    const data: GeoJSONSourceOptions['data'] = {
      type: 'FeatureCollection',
      features: args.items.map((item) => ({
        type: 'Feature',
        properties: {
          ...item,
          color: getIncidentTypeIdColor(item.incidentTypeId),
        },
        geometry: {
          type: 'Point',
          color: 'red',
          coordinates: [item.longitude, item.latitude],
        },
      })),
    }

    if (args.withCentrify) {
      const center = findCenter(args.items)
      this.map?.setCenter({ lng: center.longitude, lat: center.latitude, smooth: true })
    }

    if (source) {
      this.map?.updateSourceData(SOURCE.INCIDENTS, data)
    } else {
      this.map?.addSource(SOURCE.INCIDENTS, { type: 'geojson', data })
      this.map?.addLayer({
        id: LAYER.INCIDENTS,
        source: SOURCE.INCIDENTS,
        type: 'circle',
        paint: {
          'circle-radius': {
            base: 1.75,
            stops: [
              [12, 4],
              [22, 10],
            ],
          },
          'circle-color': ['get', 'color'],
          'circle-opacity': 0.8,
        },
      })

      // Assets should be over incidents
      this.map?.moveLayer(LAYER.INCIDENTS, LAYER.ASSETS)

      this.map
        ?.addLayerMouseEvents(LAYER.INCIDENTS)
        .on('click', (e) => {
          const properties = e.features![0].properties as RenderIncidentsItem
          args.onClick(properties)
        })
        .on('mouseover', (e) => {
          const properties = e.features![0].properties as RenderIncidentsItem
          applyStaticEventCoords(e, properties)
          this.map?.showPopup(e, {
            offset: [0, -8],
            layerId: LAYER.INCIDENTS,
            content: createPopupContentElement({ title: properties.title, description: '' }),
          })
        })
    }
  }
}
