import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { useErrorToast } from '@/components/layers/ReactQueryProvider'
import { Api } from '@/core/api'

export const QUERY_KEY_INCIDENTS_QUERY = 'incidents-query'

export const useIncidentsByQueryData = ({ enabled = true, question = '' } = {}) => {
  const { data, ...query } = useQuery({
    queryKey: [QUERY_KEY_INCIDENTS_QUERY, question],
    queryFn: () => Api.mapEvents.getIncidentsByQuery({ question }),
    placeholderData: [],
    enabled: !!question,
  })

  useErrorToast(query)

  return { data, incidentsData: isEmpty(data) ? [] : data!, ...query }
}
