import React from 'react'
import { Colors } from '@/styles/colors'
import { Icon } from '../Icon'
import { Button, ButtonProps } from './Button'

interface CloseButtonProps extends Pick<ButtonProps, 'className' | 'onClick'> {
  color?: ValueOf<typeof Colors>
}

export const CloseButton: React.FC<CloseButtonProps> = ({ className, color = Colors.Sienna, onClick }) => {
  return (
    <Button className={className} onClick={onClick}>
      <Icon name="CloseIcon" fillColor={color} />
    </Button>
  )
}
