import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { useErrorToast } from '@/components/layers/ReactQueryProvider'
import { Api } from '@/core/api'

export const QUERY_KEY_INCIDENTS = 'map-incidents'

export const useIncidentsData = ({ enabled = true } = {}) => {
  const { data, ...query } = useQuery({
    queryKey: [QUERY_KEY_INCIDENTS],
    queryFn: () => Api.mapEvents.getIncidents(),
    placeholderData: [],
    enabled,
  })

  useErrorToast(query)

  return { data, incidentsData: isEmpty(data) ? [] : data!, ...query }
}
