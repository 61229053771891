import React, { useState } from 'react'
import { Button } from '@/components/common/Button'
import { Textarea } from '@/components/common/Input'
import { useFiltersContext } from '@/components/pages/SituationalAwareness/context/FiltersContextProvider'
import styles from './DialogPopup.module.scss'

interface DialogPopupProps {
  close(): void
}

export const DialogPopup: React.FC<DialogPopupProps> = ({ close }) => {
  const { filterQuery, applyFilterQuery, resetFilters } = useFiltersContext()
  const [filterQuestion, setFilterQuestion] = useState(filterQuery)

  const onChange = (query: string) => setFilterQuestion(query)
  const onRun = () => {
    applyFilterQuery(filterQuestion)
    close()
  }
  const onCancel = () => {
    resetFilters()
    close()
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>AI Co-Pilot</div>
      </div>
      <div className={styles.body}>
        <Textarea
          placeholder="Start typing text of the prompt"
          rows={12}
          resize="none"
          defaultValue={filterQuestion}
          onChange={(e) => onChange(e.target.value)}
        />
        <Button
          label="Run"
          type="submit"
          size="md"
          width="fit-content"
          alignSelf="flex-end"
          isDisabled={!filterQuestion}
          onClick={onRun}
        />
      </div>
      <div className={styles.footer}>
        <Button className={styles.cancelBtn} label="Cancel" size="md" onClick={onCancel} />
      </div>
    </div>
  )
}
