import * as Sentry from '@sentry/nextjs'
import axios, { AxiosError, isAxiosError, isCancel } from 'axios'

export type { AxiosError }

export { isAxiosError, isCancel }

export const ROUTE = {
  LINK_PREVIEW_IMAGE: '/link-preview-image',
  DEMO: {
    GET_DATASETS: '/demo/get-datasets',
    GET_MESSAGES: '/demo/get-messages',
  },
  MAP_EVENTS: {
    ASSETS: '/map-events/get-assets',
    INCIDENTS: '/map-events/get-incidents',
    INCIDENTS_QUERY: '/map-events/query-incidents',
    INCIDENTS_COUNT: '/map-events/get-incidents-count',
    INCIDENT_TYPES: '/map-events/incident-types',
    INCIDENT_IMAGE: '/map-events/incident-image',
    INCIDENT_LOCATIONS: '/map-events/incident-locations',
    SEVERITY_LEVELS: '/map-events/severity-levels',
    TARGET_PHOTO: '/map-events/get-target-photo',
  },
  // REPORTS: {
  //   LIST: '/reports/list',
  //   DOWNLOAD_URL: '/reports/download-url',
  //   THUMBNAIL: '/reports/get-thumbnail',
  // },
}

export const readAxiosError = (error: AxiosError | Error) => {
  const data = isAxiosError(error) ? (error.response?.data as { error?: string; message?: string }) : {}
  return data?.error || data?.message || error.message
}

export const instance = axios.create({
  baseURL: '/api',
  timeout: 20000,
})

instance.interceptors.request.use((config) => {
  // config.headers.Authorization = ''
  return config
})

instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    let payload: string = error?.message
    try {
      payload = JSON.stringify(error.response?.data || error)
    } catch (err) {
      //
    }

    Sentry.captureException(payload, {
      extra: {
        place: 'internal-api',
        stack: error.stack,
        status: error.status || error.response?.status,
        message: error.message,
        code: error.code,
      },
    })
    return Promise.reject(error)
  },
)
